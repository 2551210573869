const hook = {
    mounted(){

    setTimeout(function () {

      const accessToken = "pk.eyJ1IjoiMW1hIiwiYSI6ImNsMWFkemNxdjF4MmUzYnF6Y2cyZXpkOWgifQ.4tuOZPMdPOWI-JEEysqoLQ";
      var map = L.map('chat-map').setView([42.847706, -2.681125], 15);
      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
          maxZoom: 22,
          id: "mapbox/streets-v11",
          tileSize: 512,
          zoomOffset: -1,
          accessToken: accessToken
      }).addTo(map);
      var marker;
      map.on('click', function (e) {
        if (marker) {
          map.removeLayer(marker);
        }
        marker = new L.Marker(e.latlng).addTo(map);
        marker._icon.classList.add("map-marker");
        var input = document.querySelector('#reset-bot_text');
        var inputLugar = document.querySelector('#input-lugar');
        var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + e.latlng.lat + ',' + e.latlng.lng + '&sensor=true&key=AIzaSyCpO08ouqCcQWjmLjdYL1mCq0Y6WrEBQ2o';
        fetch(url)
          .then((resp) => resp.json())
          .then(function (data) {
            let m = data.results;
            var address = m[0].formatted_address;
            if (input) {
              input.value = "<iframe class='border-0 w-full' style='height:230px' src='https://maps.google.com/maps?q="+e.latlng.lat+","+e.latlng.lng+"&z=18&output=embed' frameborder='0' title='"+address+"'></iframe>";
              // inputLugar.value = e.latlng.lat+","+e.latlng.lng;
              inputLugar.value = address;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });

      }, 1);

    }
  
  }
  export {hook}
  