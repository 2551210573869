const hook = {
    supportsSpeechRecognition() {
        return "webkitSpeechRecognition" in window;
    },
    mounted() {
        if (!this.supportsSpeechRecognition()) return;
        let hook = this;
        window.addEventListener("sendFragment", function(evt) {
            hook.pushEvent("set_speech_input", {
                id: hook.el.id,
                utt: evt.detail
            })
        }, false);
        window.addEventListener("sendFinal", function(evt) {
            let form  = hook.el.querySelector("form");
            form.dispatchEvent(
                new Event("submit", {bubbles: true, cancelable: true})
            );
            //form.submit();
            // hook.pushEvent("utterance", {
            //     text: evt.detail
            // })
        }, false);
        
        this.initializeEventHandlers(this);

        // Get the assigns from the element attribute list into a tidy parameter
        // list that also include a reference to the hook itself.
        let args = Object.entries(this.el.attributes)
            .map((a) => a[1])
            .reduce(function (acc, attribute) {
                acc[attribute.name.replaceAll("-", "_")] = attribute.value;
                return acc;
            }, { self: this })
        args.hook = this;
        this.initEvent(args);
    },

    // This will initialize a snake case event based on any camel case method
    // that ends with "Event" in the target hook. These events have access to the
    // hook under the "self" variable, and expect to always get an id from the
    // server matching this hook element id. This will ensure that any of these
    // events will trigger only on the proper hook. To properly target self, use
    // this function inside a hook function (I.E: mount) and pass "this" as the
    // parameter.
    initializeEventHandlers(self) {
        let events = Object.entries(self).filter(function (a) {
            return a[0].endsWith("Event");
        }).map(function (a) {
            return {
                function: a[1],
                name: a[0].replace(/([A-Z].)/g, (match) => ("_" + match).toLowerCase()).replace(/_event$/, "")
            }
        })

        // Events triggered by the server are handled by every hook in liveview, so
        // we wrap the target function event inside a function that checks the id
        // passed by the server in it's parameters and uses it to check if it's
        // this hook the one who has to act on this event. You may think of this as
        // a PubSub topic.
        //
        // This wrapper function also initializes the "self" parameter that every
        // Event function expects. This is because these functions run in the
        // window scope, not the hook scope, and this would not be available
        // otherwise.

        events.forEach(function (event) {
            self.handleEvent(event.name, function (params) {
                if (self.el.id == params.id || params.id === undefined) {
                    params.self = self;
                    return event.function(params);
                }
            });
        })
        return self
    },

    initEvent(args) {
        let self = args.self;
        self.hook = args.hook;
        self.speech_input = self.el.querySelector("#speech-input");
        self.input = self.el.querySelector("#reset-bot_text");

        self.recognition = new webkitSpeechRecognition();
        self.recognition.lang = "es-ES";
        self.recognition.continuous = false;
        self.recognition.interimResults = true;
        self.recognition.onstart = this.onRecognitionStart;
        self.recognition.onerror = this.onRecognitionError;
        self.recognition.onend = this.onRecognitionEnd;
        self.recognition.onresult = this.onRecognitionResult;
        self.recognition = self.recognition;
        self.recognition.text_input = self.input;
        self.recognition.hook = args.hook;

        self.recognition.state = {
            isRecognizing: false,
            finalTranscript: "",
            interimTranscript: ""
        };

        return self
    },
    onRecognitionStart(){
        this.state = {
            isRecognizing: true,
            finalTranscript: "",
            interimTranscript: ""
        }
    },

    onRecognitionResult(event){
        let finalTranscript = this.state.finalTranscript;
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
                finalTranscript += event.results[i][0].transcript;
            } else {
                interimTranscript += event.results[i][0].transcript;
            }
        }
        utt = finalTranscript + interimTranscript;
        this.text_input.value = utt;

        //ev =  new CustomEvent("sendFragment", {detail: utt});
        //window.dispatchEvent(ev)
        return { finalTranscript, interimTranscript };

    },

    onRecognitionEnd(){
        console.log("RECOGNITION END");
        this.state.isRecognizing = false;
        this.state.finalTranscript =  this.state.finalTranscript,
        this.state.interimTranscript = this.state.interimTranscript
        this.stop();
        ev =  new CustomEvent("sendFinal", {detail: this.state.finalTranscript + this.state.interimTranscript});
        window.dispatchEvent(ev)
        // SEND TO PHOENIX
    },

    onRecognitionError(event){
        console.log(event)
        console.error(event.error);
    },

    startRecognitionEvent({self}) {
        console.log("js start recognition");
        if (self.recognition.state.isRecognizing) {
            self.recognition.stop();
            return;
        }
        console.log("START")
        console.log(self.recognition)
        self.recognition.start();
    }
}
export { hook }
