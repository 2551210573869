const hook = {
    mounted(){
      this.initializeEventHandlers(this);
  
      // Get the assigns from the element attribute list into a tidy parameter
      // list that also include a reference to the hook itself.
      let args = Object.entries(this.el.attributes)
        .map((a) => a[1])
        .reduce(function(acc, attribute){
          acc[attribute.name.replaceAll("-", "_")] = attribute.value;
          return acc;
        }, {self: this})
  
      this.initEvent(args);
    },
  
    // This will initialize a snake case event based on any camel case method
    // that ends with "Event" in the target hook. These events have access to the
    // hook under the "self" variable, and expect to always get an id from the
    // server matching this hook element id. This will ensure that any of these
    // events will trigger only on the proper hook. To properly target self, use
    // this function inside a hook function (I.E: mount) and pass "this" as the
    // parameter.
    initializeEventHandlers(self){
      let events = Object.entries(self).filter(function(a){
        return a[0].endsWith("Event");
      }).map(function(a){
        return{
        function: a[1],
          name: a[0].replace(/([A-Z].)/g, (match) => ("_" + match).toLowerCase()).replace(/_event$/, "")
        }
      })
  
      // Events triggered by the server are handled by every hook in liveview, so
      // we wrap the target function event inside a function that checks the id
      // passed by the server in it's parameters and uses it to check if it's
      // this hook the one who has to act on this event. You may think of this as
      // a PubSub topic.
      //
      // This wrapper function also initializes the "self" parameter that every
      // Event function expects. This is because these functions run in the
      // window scope, not the hook scope, and this would not be available
      // otherwise.
      events.forEach(function(event){
        self.handleEvent(event.name, function(params){
          if(self.el.id == params.id || params.id === undefined){
            params.self = self;
            return event.function(params);
          }
        });
      })
      return self
    },
  
    initEvent(args){
      let self = args.self;
      self.chat_box = self.el.querySelector("input:not(.hidden)")
      self.messages_list = self.el.querySelector("#message-list")
      return self
    },
  
    focusOnChatInputEvent({self}){
      //self.chat_box.focus()
      return self
    },
  
    clearChatInputEvent({self}){
      //self.chat_box.value = ""
      let all_inputs = self.el.querySelectorAll("input");
      all_inputs.forEach( function(el) {
        if (el.type == "text"){
          el.value = "";
        } 
      });
      return self
    },
  
    scrollToChatInputEvent({self}){
      setTimeout(function() {
        self.messages_list.scrollTop = self.messages_list.scrollHeight;
      }, 50);
      return self
    },
  }
  export {hook}
  